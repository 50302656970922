import {NavLink} from "react-router-dom";
import classes from "./NavList.module.scss";
import CategoriesList from "./CategoriesList/CategoriesList";

const NavList = () => {
    return (
        <ul className={`${classes["navbar-nav"]} navbar-nav`}>
            <li className="nav-item  px-0 px-xl-3">
                <NavLink
                    exact
                    className={`${classes["nav-link"]} nav-link`}
                    to="/"
                    activeClassName={classes["active-link"]}
                >
                    <div
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                    >
                        Home
                    </div>
                </NavLink>
            </li>
            <li className="nav-item dropdown px-0 px-xl-3">
                <CategoriesList/>
            </li>
            <li className="nav-item  px-0 px-xl-3">
                <NavLink
                    className={`${classes["nav-link"]} nav-link`}
                    to="/company?ordering=-created_at"
                    activeClassName={classes["active-link"]}
                >
                    <div
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                    >
                        Companies
                    </div>
                </NavLink>
            </li>
            <li className="nav-item  px-0 px-xl-3">
                {/*<NavLink*/}
                {/*    className={`${classes["nav-link"]} nav-link`}*/}
                {/*    to="/subscription"*/}
                {/*    activeClassName={classes["active-link"]}*/}
                {/*>*/}
                {/*    <div*/}
                {/*        data-bs-toggle="collapse"*/}
                {/*        data-bs-target=".navbar-collapse.show"*/}
                {/*    >*/}
                {/*        Subscription*/}
                {/*    </div>*/}
                {/*</NavLink>*/}
            </li>
            <li className="nav-item px-0 px-xl-3">
                <NavLink
                    className={`${classes["nav-link"]} nav-link`}
                    to="/news"
                    activeClassName={classes["active-link"]}
                >
                    <div
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                    >
                        News
                    </div>
                </NavLink>
            </li>
            <li className="nav-item px-0 px-xl-3">
                <NavLink
                    className={`${classes["nav-link"]} nav-link`}
                    to="/blogs"
                    activeClassName={classes["active-link"]}
                >
                    <div
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                    >
                        Blogs
                    </div>
                </NavLink>
            </li>

        </ul>
    );
};

export default NavList;
